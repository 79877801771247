.css-26l3qy-menu {
	z-index: 100 !important;
}

.changeRoleModal {
	position: relative;
	overflow-y: auto;
	display: flex;
	max-height: calc(100% - 64px);
	flex-direction: column;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
		0px 9px 46px 8px rgb(0 0 0 / 12%);
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: #fff !important;
}

.css-18ng2q5-group {
	color: #000 !important;
	font-size: 100% !important;
	font-weight: 600 !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.student-pagination .Mui-selected {
	background-color: #002868 !important;
}

.flex {
	display: flex;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: end;
}

.align-items-center {
	align-items: center;
}

.video-response iframe {
	left:0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.slick-track {
	margin-left: initial !important;
	margin-right: initial !important;
}

.slick-prev:before, .slick-next:before {
	color: #000000!important;
}

/* Assessment Slider */
.MuiSlider-root.Mui-disabled {
	color: #1976d2!important;
}

.map-container {
  height: 100%;
  width: 100%;
}

.gm-ui-hover-effect {
	display: none!important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}