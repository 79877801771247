.intl-tel-input {
	display: block;
	width: 100%;
}
.intl-input {
	display: block;
	width: 100%;
	font-weight: normal;
	color: #343a40;
	background-color: white;
	background-clip: padding-box;
	border: 1px solid #bfc9d8;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	height: calc(2.5rem + 2px);
	padding: 0.375rem;
	font-size: 0.875rem;
	line-height: inherit;
	border-radius: 0.25rem;
}
.intl-input.error {
	border-color: #bf0a30;
}

/* Fix for iOS force zooming inputs */
@media (max-width: 48rem) {
	.intl-input {
		font-size: 16px;
	}
}

/* Unstyle the caret on <select>'s in IE10+. */
.intl-input::-ms-expand {
	background-color: transparent;
	border: 0;
}

.intl-input::placeholder {
	color: #bfc9d8; /* $gray_a / #aaaaaa */
	opacity: 1; /* Override Firefox's unusual default opacity; */
}

.intl-input:focus {
	color: #343a40;
	background-color: white;
	border-color: #4782e0;
	outline: 0;
}

.intl-input:disabled,
.intl-input[readonly] {
	cursor: not-allowed;
	background-color: #f6f7fa;
	/* iOS fix for unreadable disabled content; */
	opacity: 1;
}
