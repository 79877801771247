@font-face {
	font-family: 'Barlow';
	src: url('../assets/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
	font-weight: normal;
	font-display: block;
}

@font-face {
	font-family: 'Barlow';
	src: url('../assets/fonts/Barlow/Barlow-Medium.ttf') format('truetype');
	font-weight: 500;
	font-display: block;
}

@font-face {
	font-family: 'Barlow';
	src: url('../assets/fonts/Barlow/Barlow-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-display: block;
}

@font-face {
	font-family: 'Barlow';
	src: url('../assets/fonts/Barlow/Barlow-ExtraBold.ttf') format('truetype');
	font-weight: 700;
	font-display: block;
}

@font-face {
	font-family: 'Barlow';
	src: url('../assets/fonts/Barlow/Barlow-Black.ttf') format('truetype');
	font-weight: 900;
	font-display: block;
}
